import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import {TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import { useMutation, gql } from '@apollo/client';
import Logo from "../images/logo.png"
import {setLogin} from "../services/auth";
import ChangePassword from "../components/ChangePassword";
import * as auth from "../services/auth";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://capensis.pt/">
                Capensis LDA
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function Login(props) {
    useEffect(()=>auth.logout(), [props.location])
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const [login, {data}] = useMutation(gql `
        mutation ($email: String!, $password: String!) {
            Login(
              email: $email, 
              password: $password
            ){
              userId
              name
              partner
              api_token
              error
              message
              needChangePassword
            }
        }
    `)

    const history = useHistory();

    async function session(data) {
        setOpen(true);
        if(data.email!=="" && data.password!==""){
            const res = await login({variables: {email: data.email, password: data.password}})
            if(res.data.Login.error===0){
                setAlert({
                    open: true,
                    message: res.data.Login.message,
                    type: 'success'
                })
                setLogin(res.data.Login.name, res.data.Login.api_token , res.data.Login.partner);
                if(res.data.Login.needChangePassword===false){
                    history.push("/dashboard")
                }
            }else{
                setAlert({
                    open: true,
                    message: res.data.Login.message,
                    type: 'error'
                })
            }
        }else{
            setAlert({
                open: true,
                message: "Falta preencher campos",
                type: 'error'
            })
        }
        setOpen(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img style={{height: 200}} src={Logo} alt={"Logo Banksii"}/>
                <Form onSubmit={data=>session(data)} className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Iniciar sessão
                    </Button>
                </Form>
                <Collapse in={alert.open} >
                    <Alert severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {data!==undefined?(
                <ChangePassword open={data.Login.needChangePassword===true}/>
            ):null}
        </Container>
    );
}

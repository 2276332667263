import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Card, CardContent, Divider, Grid, TextField} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: '20px',
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function CustomerAdd(props){
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const [nif, setNif] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailHost, setEmailHost] = useState("");
    const [emailSend, setEmailSend] = useState("");
    const [emailName, setEmailName] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [emailPort, setEmailPort] = useState("");
    const [disableButtons, setDisableButtons]=useState(false)
    const [register] = useMutation(gql `
        mutation NewCustomer ($name: String!, $nif: Int!, $email:String!, $phoneNumber:String!, $emailHost: String, $emailName:String, $emailPassword:String, $emailPort:String, $emailSend:String){
            NewCustomer (
                name: $name,
                nif: $nif,
                email:$email,
                phoneNumber:$phoneNumber
                emailSmtpSecure:true
                emailHost:$emailHost
                emailSend:$emailSend
                emailName:$emailName
                emailPassword:$emailPassword
                emailPort:$emailPort
            )
            {id message error}
        }
    `);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    async function registerCustomer(){
        if(name!=="" && nif!=="" && email!==""){
            if(nif.length===9){
                const nifCustomer=parseInt(nif);
                const res = await register({variables:{
                        name, nif:nifCustomer, email, phoneNumber, emailHost, emailName, emailPort, emailPassword
                    }});
                enqueueSnackbar(res.data.NewCustomer.message, {variant:res.data.NewCustomer.error===0?"success":"error"});
                if(res.data.NewCustomer.error===0){
                    setDisableButtons(true);
                }
            }else{
                enqueueSnackbar('O nif é invalido', { variant:"error" });
            }
        }else{
            enqueueSnackbar('Algumas das informações do cliente não foram preenchidas', { variant:"error" });
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Registo de cliente
                        </Typography>
                        <Divider/>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Introdução</b></Typography></StepLabel>
                                    <StepContent>
                                        <Typography>Este assistente irá auxiliar na criação do seu novo cliente</Typography>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Informações Basicas</b></Typography></StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2} sm={12} md={6}>
                                            <Grid item sm={6} md={12}>
                                                <Grid container sm={12}>
                                                    <Grid item sm={12}>
                                                        <TextField value={nif} onChange={e=>setNif(e.target.value)} label={"NIF"} type={"number"} variant={"standard"} name={"nif"}/>
                                                        <Button variant={"outlined"} title={"Preencher automaticamente"} color={"primary"}><SearchIcon/></Button>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={name} onChange={e=>setName(e.target.value)} label={"Nome"} type={"text"} variant={"standard"} name={"name"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={email} onChange={e=>setEmail(e.target.value)} label={"Email"} type={"email"} variant={"standard"} name={"email"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={phoneNumber} onChange={e=>setPhoneNumber(e.target.value)} label={"Telefone"} type={"number"} variant={"standard"} name={"phoneNumber"}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Parametros de envio de comunicações</b></Typography></StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2} sm={6}>
                                            <Grid item sm={12}>
                                                <Grid container sm={12}>
                                                    <Grid item sm={4}>
                                                        <TextField value={emailHost} onChange={e=>setEmailHost(e.target.value)} label={"Servidor de envio"} variant={"standard"} name={"emailHost"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={emailSend} onChange={e=>setEmailSend(e.target.value)} label={"Email de envio"} variant={"standard"} name={"emailSend"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={emailName} onChange={e=>setEmailName(e.target.value)} label={"Nome do email de envio"} variant={"standard"} name={"emailName"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={emailPassword} onChange={e=>setEmailPassword(e.target.value)} label={"Password de email"} variant={"standard"} name={"emailPassword"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={emailPort} onChange={e=>setEmailPort(e.target.value)} label={"Porta do servidor"} variant={"standard"} name={"emailPort"}/>
                                                    </Grid>
                                                    <Grid item sm={12}><p>As configurações são do servidor smtp. Caso não saiba é possivel alterar mais tarde</p></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Resumo</b></Typography></StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2} sm={6}>
                                            <Grid item sm={12}>
                                                <Grid container spacing={1}  sm={12} style={{fontSize: 17}} >
                                                    <Grid item sm={12} className={"header-title"}>
                                                        <h3>Informações do Cliente</h3>
                                                    </Grid>
                                                    <Grid item sm={6}><b >Nome: </b><span>{name}</span></Grid>
                                                    <Grid item sm={6}><b >Email: </b><a style={{color: 'black'}} href={"mailTo:"+email}>{email}</a></Grid>
                                                    <Grid item sm={6}><b >NIF: </b><span>{nif}</span></Grid>
                                                    <Grid item sm={6}><b >Numero Telefone: </b><span>{phoneNumber}</span></Grid>
                                                </Grid>
                                                <Grid container spacing={1} sm={12} style={{fontSize: 17}} >
                                                    <Grid item sm={12} className={"header-title"}>
                                                        <h3>Parametros de envio</h3>
                                                    </Grid>
                                                    <Grid item sm={6}><b>Email de envio: </b><span>{emailSend}</span></Grid>
                                                    <Grid item sm={6}><b>Nome de envio: </b><span>{emailName}</span></Grid>
                                                    <Grid item sm={6}><b>Servidor de envio: </b><span>{emailHost}</span></Grid>
                                                    <Grid item sm={6}><b>Porta SMTP: </b><span>{emailPort}</span></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                            </Stepper>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleBack}
                                        className={classes.button}
                                        disabled={disableButtons}
                                    >
                                        Voltar
                                    </Button>
                                    {activeStep!==3?(
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={disableButtons}
                                        >
                                            Avançar
                                        </Button>
                                    ):(
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={registerCustomer}
                                        className={classes.button}
                                        disabled={disableButtons}
                                        >
                                        Registar
                                    </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
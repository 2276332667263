import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: '20px',
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function ChangelogAdd(props){
    const classes = useStyles();
    const [open, setOpen]= useState(false)
    const  history = useHistory();
    const [register] = useMutation(gql `
        mutation RegisterChangelog($version: String, $details: String) {
          RegisterChangelog(version: $version, details: $details){
            version
            details {title description}
          }
        }
    `)
    const { enqueueSnackbar } = useSnackbar();
    const [campos, setCampos] = useState([{num:0}]);
    const [data, setData] = useState({
        versao:"",
        detalhes:[{
            title:"",
            description:""
        }]
    })
    const add = ()=>{
        setCampos(campos.concat({num:campos.length}))
        const tmp = data;
        tmp.detalhes.push({title:"", description: ""})
        setData(tmp)
    }

    const remove = (idx)=>{
        if(campos.length!==1){
            setCampos(campos.filter((s, index)=>index!==idx))
            const tmp = data;
            tmp.detalhes.pop()
            setData(tmp)
        }
    }

    const setVersion = (e)=>{
        const tmp = data;
        tmp.versao=e.target.value;
        setData(tmp)
    }

    const setDetails = (value, camp, position)=>{
        const tmp = data;
        console.log(position)
        tmp.detalhes[position][camp]=value
        //tmp.detalhes[0]["title"]=value
        setData(tmp)
    }

    const verify = (data)=>{
        if(data.versao!==""){
            let canNext=true
            data.detalhes.map(row=>{
                if(row.description.replace(/<\/?[^>]+(>|$)/g, "")==="" || row.title==="")
                    canNext=false
            })
            return canNext
        }else {
            return  false
        }
    }

    const save = async ()=>{
        setOpen(false)
        console.log(verify(data))
        if(verify(data)){
            register({variables:{version:data.versao, details:JSON.stringify(data.detalhes)}}).then(()=>{
                enqueueSnackbar('Sucesso', { variant:"success" })
                history.push('/changelog')
            })

        }else{
            enqueueSnackbar('Por favor preencha todos os campos', { variant:"error" });
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Registo de alterações
                        </Typography>
                        <Divider/>
                        <div className={classes.root}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextField onChange={setVersion} style={{marginBottom: 10}} variant={"outlined"} label={"Versão"} />
                                    <Typography  variant={"h5"} className={"header-title"}>Detalhes: </Typography>
                                    {campos.map((data, idx)=>(
                                        <Accordion expanded={true} style={{padding: 10}}>
                                            <AccordionDetails>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                        <TextField onChange={(e)=>setDetails(e.target.value, 'title', idx)} label={"Titulo"} fullWidth variant={"outlined"}/>
                                                    </Grid>
                                                    <Grid xs={12} style={{marginTop: 10}}>
                                                        <SunEditor height={"200px"} placeholder={"Descrição"} onChange={e=>setDetails(e, 'description', idx)}  lang={"pt_br"} />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                            {/*<center><Button onClick={()=>remove(data.num)} variant={"outlined"} color={"primary"}>Remover</Button></center>*/}
                                        </Accordion>
                                    ))}
                                    <Button onClick={()=>setOpen(true)} variant={"contained"} color={"primary"} style={{width: '100%'}}>Guardar</Button>

                                </Grid>
                                <Grid item xs={1} direction={"column"}>
                                    <Button onClick={add} variant={"contained"} color={"primary"} style={{width: '100%'}}>Adicionar</Button>
                                    <Button onClick={()=>remove(campos.length-1)}  variant={"contained"} color={"secondary"} style={{width: '100%'}}>Remover</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}

            >
                <DialogTitle >{"Deseja guardar o registo de alterações?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={save} color="primary" autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
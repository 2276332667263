import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./services/PrivateRoute";
import CustomerAdd from "./pages/Customer/Add";
import CustomerList from "./pages/Customer/List";
import ChangeLogList from "./pages/ChangeLogs/List";
import ChangelogAdd from "./pages/ChangeLogs/Add";
import ChangelogEdit from "./pages/ChangeLogs/Edit";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/dashboard" component={()=>"dashboard"} />
            <PrivateRoute exact path="/customer/add" component={CustomerAdd} />
            <PrivateRoute exact path="/customer" component={CustomerList} />
            <PrivateRoute exact path="/changelog" component={ChangeLogList} />
            <PrivateRoute exact path="/changelog/add" component={ChangelogAdd} />
            <PrivateRoute exact path="/changelog/:id" component={ChangelogEdit} />
        </Switch>
    </BrowserRouter>
)

export default Routes;
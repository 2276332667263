import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DataTable from "react-data-table-component"
import {useLazyQuery, useQuery} from "@apollo/client";
import {gql} from "@apollo/client/core";
import Button from "@material-ui/core/Button";
import {useSnackbar} from "notistack";

export default function CustomerList(props){

    const GetCustomers = gql `
        query getCustomers($perPage:Int!, $page:Int! ){
            Customers(perPage: $perPage, page:$page){
                data {
                    id
                    name
                    nif
                }
            page
            perPage
            total
            totalPages
            }
        }

    `;

    const login = gql `
      query MakeLoginPartner ($id: ID){
        PartnerLogin(id:$id){
            data{
                BKID
                username
                token
            }
            message
            error
        }
      }  
    `;

    const {data, loading : isLoading, fetchMore } = useQuery(GetCustomers , {variables: {"perPage": 10, "page": 0}, fetchPolicy: "cache-and-network"});
    const [loginPartner, {loading, data:loginPartnerData}] = useLazyQuery(login, {
        onCompleted: (data) => {
            if(data.PartnerLogin.error==0){
                window.open(`https://banksii.pt/login/App/services/PartnerLogin.php?username=${data.PartnerLogin.data.username}&token=${data.PartnerLogin.data.token}&BKID=${data.PartnerLogin.data.BKID}`, '_blank');
            }
        },
        fetchPolicy: "network-only"
    });


    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(!isLoading){
            if (fetchMore) {
                fetchMore({
                    updateQuery: update,
                    variables: {"perPage": perPage, "page": page}
                })
            }
        }
    }, [page, perPage]);

    function handleClickLogin(id){
        loginPartner({variables:{id}});
    }

    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
        },{
            name: 'NIF',
            selector: 'nif',
            sortable: true,
        },{
            name: 'Entrar',
            cell:(row)=><Button onClick={()=>handleClickLogin(row.id)} variant={"contained"} color={"primary"}>Aceder a portal</Button>,
            sortable: true,
        },
    ];

    function loadMore(newPage=page, newPerPage=perPage){
        setPage(newPage)
        setPerPage(newPerPage)
    }

    function update(previousResult, { fetchMoreResult }){
        if (!fetchMoreResult) {
            return previousResult;
        }
        return { ...fetchMoreResult }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Listagem de cliente
                        </Typography>
                        <Divider/>
                        {!data?(
                            <center>
                                <CircularProgress />
                            </center>
                        ):<DataTable
                            columns={columns}
                            data={data.Customers.data}
                            pagination
                            paginationServer
                            paginationTotalRows={data.Customers.total}
                            paginationDefaultPage={data.Customers.page}
                            onChangeRowsPerPage={(e)=>loadMore(page, e)}
                            onChangePage={(e)=>loadMore(e, perPage)}
                        />}

                    </CardContent>
                </Card>
            </Grid>
            <Dialog
                open={loading}
                onClose={()=>null}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"A iniciar sessão"}</DialogTitle>
                <DialogContent>
                    <center>
                        <CircularProgress />
                    </center>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}
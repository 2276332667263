import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Form} from "@unform/web";
import {TextField} from "unform-material-ui"
import {useMutation, gql} from "@apollo/client";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

export default function ChangePassword ({open}){
    const [mutation , {data}] = useMutation(gql `
        mutation ($password:String!, $passwordConfirm:String!){
          ChangePassword(
            password: $password
            passwordConfirm: $passwordConfirm
          ){
            message
            error
          }
        }
    `);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const history = useHistory();
    const delay = ms => new Promise(res => setTimeout(res, ms));


    async function updatePassword (data){
        if(data.password!=="" && data.passwordConfirm!==""){
            if(data.password===data.passwordConfirm){
                const res = await mutation({variables: data});
                setAlert({
                    open: true,
                    message: res.data.ChangePassword.message,
                    type: res.data.ChangePassword.error===0?"success":"error"
                });
                if(res.data.ChangePassword.error===0){
                    await delay(2000);
                    history.push("/dashboard")
                }
            }else{
                setAlert({
                    open: true,
                    message: "As passwords não são iguais",
                    type: "error"
                })
            }
        }else{
            setAlert({
                open: true,
                message: "Por favor preenche os campos corretamente",
                type: "error"
            })
        }
    }

    return(
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Alteração de password</DialogTitle>
            <DialogContent style={{padding: 20}}>
                <DialogContentText>
                    Devido a ser o seu primeiro login por favor altere a sua password.
                </DialogContentText>
                <Form onSubmit={updatePassword}>
                    <TextField
                        variant={"outlined"}
                        autoFocus
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        required={true}
                        name={"password"}
                    />
                    <TextField
                        variant={"outlined"}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Confirmação de password"
                        type="password"
                        fullWidth
                        required={true}
                        name={"passwordConfirm"}
                    />

                    <Collapse in={alert.open} style={{marginTop: 5, marginBottom: 5}}>
                        <Alert severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Collapse>

                    <Button fullWidth type={"submit"} color={"primary"} variant={"contained"}>Alterar password</Button>

                </Form>
            </DialogContent>
        </Dialog>
    )
}
import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Card,
    CardContent,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DataTable from "react-data-table-component"
import {useLazyQuery, useQuery} from "@apollo/client";
import {gql} from "@apollo/client/core";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from "react-router-dom";

export default function ChangeLogList(props){

    const GetChangeLog = gql `
        query ChangeLog{
          ChangeLog{
            id
            version
            details {title description}
          }
        }

    `;

    const DeleteChangeLog = gql`
       query removeChangelog ($id: ID!){
            removeChangelog(id: $id){
                id
            }
      }  
    `

    function update(previousResult, { fetchMoreResult }){
        if (!fetchMoreResult) {
            return previousResult;
        }
        return { ...fetchMoreResult }
    }

    const [open, setOpen]= useState(false)

    const {data, loading : isLoading, fetchMore } = useQuery(GetChangeLog , {fetchPolicy: "cache-and-network"});
    const [removeID, setRemoveID] = useState(0)
    const [removeChangelog, {loading, data:deleted}] = useLazyQuery(DeleteChangeLog, {
        onCompleted: (data) => {
            fetchMore({
                updateQuery: update
            })
        },
        fetchPolicy: "network-only"
    });

    const remove = ()=>{
        setOpen(false)
        let id = removeID
        removeChangelog({variables: {id}})
    }
    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent style={{padding: 10}}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                                    Registo de alterações
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Link style={{textDecoration: "none"}} to={"/changelog/add"}><Button variant={"contained"}  color={"primary"}>Adicionar</Button></Link>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {!data?(
                            <center>
                                <CircularProgress />
                            </center>
                        ):data.ChangeLog.map((data, idx)=>(
                            <>
                                <Typography  className={"header-title"}>Versão: {data.version}</Typography>
                                {data.details.map((row, index)=>(
                                    <Accordion style={{padding: 10}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                            <Typography  className={"header-title"}>{row.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography dangerouslySetInnerHTML={{__html:row.description}}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    ))}
                                <Link to={"/changeLog/"+data.id}><Button variant={"contained"} onClick={()=>null} color={"primary"}>Editar</Button></Link>
                                <Button variant={"contained"} onClick={()=> {
                                    setRemoveID(data.id)
                                    setOpen(true)
                                }} color={"secondary"}>Apagar</Button>
                            </>
                            ))
                        }

                    </CardContent>
                </Card>
            </Grid>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}

            >
                <DialogTitle >{"Deseja apagar o registo de alterações?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={remove} color="primary" autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
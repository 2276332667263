import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {da} from "suneditor/src/lang";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: '20px',
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function ChangelogEdit(props){
    const classes = useStyles();
    const [open, setOpen]= useState(false)
    const  history = useHistory();
    const [register] = useMutation(gql `
        mutation RegisterChangelog($id: ID, $version: String, $details: String) {
          EditChangelog(id: $id, version: $version, details: $details){
            version
            details {title description}
          }
        }
    `)
    const [getData, {loading: isLoading, data: response}]  = useLazyQuery(gql `
         query getChangelog ($id: ID!){
            getChangelog(id: $id){
                id
                version
                details {
                    title
                    description
                }
         }
      }  
    ` , { fetchPolicy: "cache-and-network", onCompleted:(data)=>{
            setValue(data.getChangelog)
        }})

    function setValue(obj){
        let objTmp = {
            version:obj.version,
            details:[{
                title:"",
                description:""
            }]
        }
        objTmp.version = obj.version
        let details = []

        obj.details.map(row=>{
            details.push({
                title:row.title,
                description:row.description
            })
        })
        objTmp.details=details
        setData({...objTmp})
        return objTmp
    }

    useEffect(()=>{
        console.log(getData({variables:{id:props.match.params.id}}))
    }, [props.match.params.id])

    const { enqueueSnackbar } = useSnackbar();
    const [campos, setCampos] = useState([{num:0}]);
    const [data, setData] = useState({
        version:"",
        details:[{
            title:"",
            description:""
        }]
    })
    const add = ()=>{
        setCampos(campos.concat({num:campos.length}))
        const tmp = data;
        tmp.details.push({title:"", description: ""})
        setData(tmp)
    }

    const remove = (idx)=>{
        if(campos.length!==1){
            setCampos(campos.filter((s, index)=>index!==idx))
            const tmp = data;
            tmp.details.pop()
            setData(tmp)
        }
    }

    const setVersion = (e)=>{
        let tmp = data;
        tmp.version=e.target.value;
        setData({
            ...tmp,
            version: e.target.value
        })
    }

    const setDetails = (value, camp, position, data)=>{
        /*if(data.version===""&&data.details[0].title===""&&data.details[0].description===""){

            var tmp = setValue(response)
        }else{
            var tmp = data;
        }*/
        let tmp = data;
        /*if(camp=== "title"){
            setData({
                ...tmp,
                details: [{
                        ...tmp.details[position],
                        title:value
                }, ...tmp.details]
            })
            console.log({
                ...tmp,
                details: [{
                    ...tmp.details[position],
                    title:value
                }, ...tmp.details]
            })
        }else{
            tmp.details[position][camp]=value
            setData(tmp)
        }*/
        let newData = tmp.details.map((row, idx)=>{
            if(idx===position){
                row[camp]=value
            }
            return {...row}
        })
        console.log(newData)
        setData({
            ...tmp,
            details: newData
        })



    }

    const verify = (data)=>{
        if(data.version!==""){
            let canNext=true
            data.details.map(row=>{
                if(row.description.replace(/<\/?[^>]+(>|$)/g, "")==="" || row.title==="")
                    canNext=false
            })
            return canNext
        }else {
            return  false
        }
    }

    const save = async ()=>{
        setOpen(false)
        if(verify(data)){
            register({variables:{id: props.match.params.id,version:data.version, details:JSON.stringify(data.details)}}).then(()=>{
                enqueueSnackbar('Sucesso', { variant:"success" })
                history.push('/changelog')
            })

        }else{
            enqueueSnackbar('Por favor preencha todos os campos', { variant:"error" });
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Registo de alterações
                        </Typography>
                        <Divider/>
                        <div className={classes.root}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextField value={data.version} onChange={e=>setVersion(e)} style={{marginBottom: 10}} variant={"outlined"} label={"Versão"} />
                                    <Typography variant={"h5"} className={"header-title"}>Detalhes: </Typography>
                                    {campos.map((row, idx)=>(
                                        <Accordion expanded={true} style={{padding: 10}}>
                                            <AccordionDetails>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                        <TextField value={data.details[idx]['title']} onChange={(e)=>setDetails(e.target.value, 'title', idx, data)} label={"Titulo"} fullWidth variant={"outlined"}/>
                                                    </Grid>
                                                    {data.details[idx]['description']!==""?(
                                                        <Grid xs={12} style={{marginTop: 10}}>
                                                            <SunEditor defaultValue={data.details[idx]['description']} height={"200px"} placeholder={"Descrição"} onChange={e=>setDetails(e, 'description', idx, data)}  lang={"pt_br"} />
                                                        </Grid>
                                                    ):null}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                    <Button onClick={()=>setOpen(true)} variant={"contained"} color={"primary"} style={{width: '100%'}}>Guardar</Button>

                                </Grid>
                                <Grid item xs={1} direction={"column"}>
                                    <Button onClick={add} variant={"contained"} color={"primary"} style={{width: '100%'}}>Adicionar</Button>
                                    <Button onClick={()=>remove(campos.length-1)}  variant={"contained"} color={"secondary"} style={{width: '100%'}}>Remover</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}

            >
                <DialogTitle >{"Deseja guardar o registo de alterações?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={save} color="primary" autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}